import { PrismaClient, PrismaTXN } from "@openqlabs/drm-db";
import { getCurrentTimestamp } from ".";

export default async function upsertListEvaluation(
  prisma: PrismaClient | PrismaTXN,
  listId: string,
  teamAccountId: string
) {
  try {
    const initialResults = {
      repoCount: 0,
      userCount: 0,
      months: [],
      maxCommitsPerCombo: 0,
      monthlyStats: {},
      teamsInfo: {},
      sharedContributors: {},
      insightsPerTracker: {},
      usersLocations: [],
      newActiveRepos: {},
    };
    await prisma.listEvaluation.upsert({
      where: {
        listId_teamAccountId: {
          listId,
          teamAccountId: teamAccountId,
        },
      },
      create: {
        list: { connect: { id: listId } },
        teamAccount: { connect: { id: teamAccountId } },
        status: "waiting",
        error: null,
        createdAt: getCurrentTimestamp(),
        updatedAt: getCurrentTimestamp(),
        ...initialResults,
      },
      update: {
        status: "waiting",
        error: null,
        createdAt: getCurrentTimestamp(),
        updatedAt: getCurrentTimestamp(),
      },
    });
  } catch (error) {
    console.info(`Error updating list evaluation to "waiting": ${error}`);
  }
}
