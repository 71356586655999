// STATIC CONSTANTS

import {
  REPO_DEPS_EVALUATION_TOPIC,
  REPO_EVALUATION_TOPIC,
  USER_DEPS_EVALUATION_TOPIC,
  USER_EVALUATION_TOPIC,
} from "./kafkaConstants";

// URLS
export const GITHUB_URL = "https://github.com";

export const REPO_IS_PRIVATE = "REPO_IS_PRIVATE";
export const REPO_IS_EMPTY = "REPO_IS_EMPTY";
export const NOT_FOUND = "NOT_FOUND";
export const NO_COMMITS_AFTER_2020 = "NO_COMMITS_AFTER_2020";
export const REPO_TOO_LARGE = "REPO_TOO_LARGE";
export const CONTACT_LIMIT_REACHED = "CONTACT_LIMIT_REACHED";
export const SECONDARY_RATE_LIMIT_REACHED = "SECONDARY_RATE_LIMIT_REACHED";
export const TOKENS_EXPIRED = "TOKENS_EXPIRED";

// ERROR MESSAGES
export const USER_NOT_FOUND = `GraphQL query failed: {"type":"NOT_FOUND","path":["user"]`;
export const REPO_NOT_FOUND = `GraphQL query failed: {"type":"NOT_FOUND","path":["repository"]`;

export const REPO_NOT_ADDED_REASONS = [
  REPO_IS_PRIVATE,
  REPO_IS_EMPTY,
  NO_COMMITS_AFTER_2020,
  REPO_TOO_LARGE,
  NOT_FOUND,
  CONTACT_LIMIT_REACHED,
];

// Size limits for List Evaluations
export const PERCENTAGE_CONSIDER_DONE = 0.98;
export const MAX_TEAMS_TO_SHOW = 300; // activity chart with top 300 teams
export const MAX_SHARED_CONTRIB_DATA = 500; // shared contributors data limit
export const MAX_SHARED_CONTRIB_REPOS = 2000; // max repos to show in list evaluation for shared contribs

export const MAX_DEPS_NUMBER = 100;

export const ONE_MINUTE = 60 * 1000;

export const ONE_DAY = 86400000;

export const NUMBER_OF_RATELIMIT_RETRIES = 5;
export const RATELIMIT_RETRY_PERIOD_MS = 2500;
export const SECONDARY_RATELIMIT_RETRY_PERIOD_MS = ONE_MINUTE * 5;
export const SLEEP_BETWEEN_ADDITIONAL_MENTIONABLE_USERS = 5000; // 5 seconds

export const LIST_EVALUATION_CRON_INTERVAL = ONE_MINUTE * 30;
export const LIST_EVALUATION_INCREMENT = 20; // percentage increment to publish list evaluation
export const LIST_EVALUATION_INCREMENT_THRESHOLD = 95; // percentage threshold from which a list re-evaluates if there is at least 1 new done evaluation
export const LIST_EVALUATION_CRON_ERRORED_INTERVAL = ONE_MINUTE * 60 * 24; // once a day retry for errored list evaluations
export const LIST_EVALUATION_CRON_DELETION_INTERVAL = ONE_MINUTE * 60 * 24; // once a day retry for deletion of lists
export const OVERVIEW_EVALUATION_CRON_INTERVAL = ONE_MINUTE * 30;
export const CONTACT_BATCH_CREATION_CRON_INTERVAL = ONE_MINUTE * 10;
export const INTERVAL_BETWEEN_CRONJOBS = ONE_MINUTE * 3;

export const TRANSACTION_OPTIONS = {
  timeout: ONE_MINUTE * 2,
  maxWait: ONE_MINUTE * 2,
};

export const MAX_CONTACTS_TO_SHOW = 1000;
export const MAX_ARRAY_SIZE_VENN = 1000;

// Kafka Client Id

// KAFKA CONSUMER CONFIGURATION
export const getEvaluationTopic = (type: string) => {
  switch (type) {
    case "repo":
      return REPO_EVALUATION_TOPIC;

    case "repo-dependencies":
      return REPO_DEPS_EVALUATION_TOPIC;

    case "user":
      return USER_EVALUATION_TOPIC;

    case "user-dependencies":
      return USER_DEPS_EVALUATION_TOPIC;

    default:
      return null;
  }
};
export const MAX_CONSUMER_RESTARTS = 10;
export function getFullYearAgoIso() {
  return new Date(
    new Date().setFullYear(new Date().getFullYear() - 1)
  ).toISOString();
}
export function getSixWeeksAgoIso() {
  return new Date(new Date().setDate(new Date().getDate() - 42)).toISOString();
}
export function getFourWeeksAgoIso() {
  return new Date(new Date().setDate(new Date().getDate() - 28)).toISOString();
}
export function getNowIso() {
  return new Date().toISOString();
}

export function getFourWeeksAgoSimple() {
  return new Date(new Date().setDate(new Date().getDate() - 28))
    .toISOString()
    .split("T")[0];
}
export function getNowSimple() {
  return new Date().toISOString().split("T")[0];
}

export function getCurrentTimestamp() {
  return new Date().toISOString();
}

export function getFourWeeksAgoMs() {
  return new Date(new Date().setDate(new Date().getDate() - 28)).getTime();
}

export function getJan12020Iso() {
  return new Date("2020-01-01").toISOString();
}

export const getPast12Months = () => {
  const mostRecentMonth = new Date().toISOString().slice(0, 7);
  const getLast12Months = (mostRecentMonth: string) => {
    const [year, month] = mostRecentMonth.split("-").map(Number);
    const months = [];
    for (let i = 0; i < 13; i++) {
      const date = new Date(year, month - 1 - i, 1);
      months.push(date.toISOString().slice(0, 7));
    }
    return months.reverse();
  };
  const months = getLast12Months(mostRecentMonth);
  return months;
};
